import React from "react"
// import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {Container, Row, Col, Badge} from "reactstrap"

import Layout from "../components/layout"
import MastHead from "../components/masthead"
import { SiteHeading, H1, SubHeading } from "../components/site-heading"
import SEO from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const post = data.mdx
  const { previous, next } = data
  const {title, date, hero, tags} = post.frontmatter  

  return (
    <Layout title={siteTitle}>        
        <SEO
        title={title}
        // description={description || post.excerpt}
        />
        {hero && 
          <MastHead tag="div" imageData={hero.childImageSharp.fluid} overlay>
            <SiteHeading>
              <H1>{title}</H1>
              <SubHeading>{date}</SubHeading>
              {tags &&
                tags.map((tag, i) => {
                  return (                    
                    <Badge className="mx-1" pill key={i} color="warning">{tag}</Badge>
                  )
                })
              }
            </SiteHeading>            
          </MastHead>                
        }  
        <Container>
            <Row>
              <Col>
              {!hero &&  
                <>               
                  <h1>{title}</h1>
                  <small>{date}</small>   
                  {tags &&
                    tags.map((tag, i) => {
                      return (
                        <Badge className="mx-1" pill key={i} color="warning">{tag}</Badge>
                      )
                    })
                  }             
                </>
              }
              <article>        
                <MDXRenderer>{post.body}</MDXRenderer>
              </article> 
              </Col>
            </Row>
        </Container>      
        
        <Container className="pt-5">
        <Row>
          <Col className="col-lg-8 col-md-10 mx-auto">
            <nav className="blog-post-nav">
                <ul
                  style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                  }}
                >
                  <li>
                    {previous && previous.fields && (
                      <Link to={previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && next.fields && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>     
    </Layout>
  )
}

// BlogPostTemplate.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $previous: String, $next: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title  
        date(formatString: "MMMM DD, YYYY")
        hero {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
      body
    }
    previous: mdx(fields: { slug: { eq: $previous } }) {
      frontmatter {
        title         
      }
      fields {
        slug
      }
    }
    next: mdx(fields: { slug: { eq: $next } }) {
      frontmatter {
        title         
      }
      fields {
        slug
      }
    }
  }
`